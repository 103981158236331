import { useParams } from 'react-router-dom';
import { IFramePageLayout } from 'components/common/atoms/Layout';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';

import { LiveIframe } from 'components/LiveIframe';

export const CustomerEdit = () => {
  const { id } = useParams();

  return (
    <IFramePageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      <LiveIframe
        styleKind="mainSectionBreadcrumb"
        path={`/customers/form`}
        queryParams={ {customer_id: id} }
      />
    </IFramePageLayout>
  );
};
