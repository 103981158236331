import styled from 'styled-components';

export const ContentPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const IFramePageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 2rem 2.25rem 0 2.25rem;
`;
