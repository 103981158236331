// General
import { useParams } from 'react-router-dom';

// Components
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { IFramePageLayout } from 'components/common/atoms/Layout';

// Controller
import { LiveIframe } from 'components/LiveIframe';

export const PassengerEdit = () => {
  const { id } = useParams();

  return (
    <IFramePageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fahrgäste Übersicht',
            href: '/passengers',
          },
        ]}
      />
      <LiveIframe
        styleKind="mainSectionBreadcrumb"
        path={`/passengers/form`}
        queryParams={ {passenger_id: id} }
      />
    </IFramePageLayout>
  );
};
