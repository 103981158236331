// Components
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { IFramePageLayout } from 'components/common/atoms/Layout';
import { LiveIframe } from 'components/LiveIframe';

export const PassengersAdd = () => {
  return (
    <IFramePageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fahrgäste Übersicht',
            href: '/passengers',
          },
        ]}
      />

      <LiveIframe styleKind="mainSectionBreadcrumb" path={`/passengers/form`} />
    </IFramePageLayout>
  );
};
