import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { IFramePageLayout } from 'components/common/atoms/Layout';
import { LiveIframe } from 'components/LiveIframe';

export const CustomersAdd = () => {
  return (
    <IFramePageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />

      <LiveIframe
        styleKind="mainSectionBreadcrumb"
        path={`/customers/form`}
        queryParams={ {} }
      />
    </IFramePageLayout>
  );
};
